<template>
  <layout-dashboard :title="title">
    <v-container fluid class="pa-0 mt-0 fill-container-height">
      <v-row class="ma-4">
        <v-col
            cols="12"
            sm="6"
            lg="4"
            xl="3"
            v-for="([title, amount], index) in gridElements"
            :key="`col-${index}`"
            class="mb-4"
        >
          <v-card class="pa-4">
            <v-row class="pa-4">
              <v-col cols="8">
                <v-row>
                  <v-card-subtitle class="px-0 pt-0">
                    {{ getContent(title, 'text') }}
                  </v-card-subtitle>
                </v-row>
                <v-row>
                  <v-chip color="primary">
                    <v-card-text class="body-1 pa-0">
                      {{ amount }}
                    </v-card-text>
                  </v-chip>
                </v-row>
              </v-col>
              <v-col cols="4" align="end">
                <v-icon :size="64">
                  {{ getContent(title, 'icon') }}
                </v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </layout-dashboard>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Overview",
  data: () => ({
    title: 'Overview',
    contents: {
      allUsersData: {
        icon: 'mdi-account-group',
        text: `All Vuyap's users`
      },
      bmSuccessUsers: {
        icon: 'mdi-credit-card-outline',
        text: 'Blue Media users with SUCCESS'
      },
      bmActiveUsers: {
        icon: 'mdi-bullseye',
        text: 'Blue Media total active users'
      },
      stripeActiveUsers: {
        icon: 'mdi-currency-usd',
        text: 'Stripe active / success users'
      },
      allTags: {
        icon: 'mdi-nfc',
        text: 'All stickers'
      },
      shopOrders: {
        icon: 'mdi-shopping',
        text: 'Shop waiting orders'
      },
      collectedByUsers: {
        icon: 'mdi-check',
        text: "Users collected"
      }
    },
    gridElements: [],
    loadingMessage: 'Fetching overview data'
  }),
  beforeMount() {
    this.setLoader({
      visible: !this.getAppOverview,
      message: this.loaderMessage
    })
  },
  mounted() {
    Object.keys(this.getAppOverview || {}).length && this.fillGridElements()
  },
  computed: {
    ...mapGetters('statistics', ['getAppOverview', 'getAllStickers']),
    loaderMessage() {
      return !this.getAppOverview ? this.loadingMessage : ''
    }
  },
  methods: {
    ...mapMutations('components', ['setLoader']),
    getContent(item, element) {
      return this.contents[item]?.[element]
    },
    flatObject(arr, e) {
      return arr.map(element => [element, e[element]])
    },
    fillGridElements() {
      const data = { ...this.getAppOverview, ...this.getAllStickers}
      this.gridElements = this.flatObject(Object.keys(data), data)
    }
  },
  watch: {
    getAppOverview(value) {
      this.setLoader({visible: !value, message: this.loaderMessage})
      this.fillGridElements()
    }
  }
}
</script>
